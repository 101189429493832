<template>
<div>
  <b-container class="py-5">
    <b-row class="py-5">
      <b-col>
        <h1 class="py-2">Import data</h1>
        <h4 class="py-2">Import data from CSV files</h4>
        <p>Reminder: first column and last row don't load</p>
      </b-col>
    </b-row>
    <b-row class="py-5">
      <b-col>
        <div v-if="messages.length > 0" class="mb-4">
          <h2>Response from server</h2>
          <div v-for="(message, index) in messages" :key="'msg-' + index">
            {{ message }}
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <file-loader :prefix="prefix" :set="set" />
      </b-col>
    </b-row>
    <b-row v-if="file">
      <b-col>
        <div class="my-4">
          <strong>keys:</strong>
          <div v-for="key in keys" :key="key">
            {{ key }}
          </div>
        </div>
        <div class="mb-4">
          <b-form-input v-model="model" placeholder="model" />
        </div>
        <div v-if="model && !importing" class="mb-4">
          <b-button class="mr-5" @click="importItems">Import</b-button>
          <b-button class="ml-5" @click="patchItems">Patch</b-button>
        </div>
        <b-row v-if="counter > 0">
          <b-col>
            <div v-if="importing"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
            imported: {{counter}} records
          </b-col>
        </b-row>
        <div class="border-top" v-for="(row, index) in file" :key="'row' + '-' + index">
          <div v-for="(value, key, index2) in row" :key="'cell' + '-' + index + '-' + index2">
            {{key}}: {{ value }}
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import FileLoader from '@/components/FileLoaderStore.vue'

export default {
  components: {
    FileLoader
  },
  created: async function () {
  },
  computed: {
    file: function () {
      let result = this.$store.state.file
      if (result) {
        let keys = Object.keys(result[0])
        keys = _.tail(keys)
        result = _.map(result, (row) => {
          return _.pick(row, keys)
        })
      }
      return result
    },
    keys: function () {
      let result = []
      if (this.file) {
        result = Object.keys(this.file[0])
      }
      return result
    }
  },
  data () {
    return {
      counter: 0,
      importing: false,
      messages: [],
      model: null,
      prefix: 'frmCloudImportApp',
      set: {
        id: 'File',
        name: 'File to load'
      }
    }
  },
  methods: {
    importItems: async function () {
      this.importing = true
      this.messages = []
      this.messages.push('adding new records started')
      // for each config.file create a config.fileChunk which is based on a maximum lenght of config.file.data of 100 records
      const chunks = []
      for (let i = 0; i < this.file.length; i += 100) {
        const chunk = this.file.slice(i, i + 100)
        chunks.push(chunk)
      }
      console.log('chunks')
      console.log(chunks)
      await async.eachSeries(chunks, async.asyncify(async (chunk) => {
        const msg = await this.$Amplify.API.put('cosmos', `/standard/${this.model}`, { body: chunk })
        this.messages.push(msg)
        console.log(chunk)
        this.counter += chunk.length
      }))
      this.model = null
      this.$store.commit('setFile', null)
      this.messages.push('adding new records completed')
      this.importing = false
    },
    patchItems: async function () {
      this.importing = true
      this.messages = []
      this.messages.push('patching started')
      // for each config.file create a config.fileChunk which is based on a maximum lenght of config.file.data of 100 records
      const chunks = []
      for (let i = 0; i < this.file.length; i += 100) {
        const chunk = this.file.slice(i, i + 100)
        chunks.push(chunk)
      }
      console.log('chunks')
      console.log(chunks)
      await async.eachSeries(chunks, async.asyncify(async (chunk) => {
        const msg = await this.$Amplify.API.put('cosmos', `/standard/${this.model}/patch`, { body: chunk })
        this.messages.push(msg)
        console.log(chunk)
        this.counter += chunk.length
      }))
      this.model = null
      this.$store.commit('setFile', null)
      this.messages.push('patching finished')
      this.importing = false
    }
  }
}
</script>
